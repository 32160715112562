import React from 'react';
import Seo from '@/components/Seo';
import Layout from '@/components/Layout';
import Typography from '@/components/Typography';
import Steps from '@/components/Steps';
import { RulesPage } from '@/utils/data/seo';

const Page = () => {
  return (
    <Layout>
      <div className="container px-4 py-5 text-dark-gray md:pb-17.5 md:pt-15 lg:max-w-[1130px]">
        <Typography as="h1" variant="header-2" className="pb-7.5">
          Regulamin
        </Typography>
        <div className="space-y-7.5 px-4">
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § I Definicje
            </Typography>
            <Typography as="p" variant="header-3">
              W niniejszym Regulaminie zostały użyte terminy z następującym
              znaczeniem:
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Usługodawca</strong> – Helpfind Sp. z o.o., z siedzibą
                  w Ostrowie Wielkopolskim przy ul. Kazimierza Pułaskiego 11,
                  63-400 Ostrów Wielkopolski, wpisaną do rejestru
                  przedsiębiorców prowadzonym przez Sąd Rejonowy Poznań - Nowe
                  Miasto i Wilda w Poznaniu, IX Wydział Gospodarczy KRS pod
                  numerem 0000385405, NIP: 6222763569, Regon 301735830.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Administrator</strong> – podmiot, który zarządza
                  serwisem. Administrator jest także właścicielem domeny
                  sprawdz-kosztorys.pl.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Serwis</strong> – portal internetowy, który znajduje
                  się pod adresem: sprawdz-kosztorys.pl.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Użytkownik</strong> – osoba fizyczna, osoba prawna
                  bądź jednostka organizacyjna, która korzysta z usług lub
                  zasobów serwisu, także za pośrednictwem Call Center.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Call Center</strong> – prowadzony przez usługodawcę
                  serwis telefoniczny.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Konsultant Call Center</strong> – osoba zatrudniona u
                  usługodawcy, zajmująca się obsługą Call Center.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Usługi</strong> – umożliwienie Użytkownikowi
                  otrzymania bezpłatnej analizy kosztorysu naprawy pojazdu.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  <strong>Polityka Prywatności</strong> – integralna część
                  Regulaminu zawierająca zasady dot. ochrony danych osobowych,
                  ich gromadzenia, przechowywania, przetwarzania.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § II Postanowienia wstępne
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Niniejszy regulamin został sporządzony o obowiązujące w Polsce
                  przepisy prawne.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Przedmiotem regulaminu są warunki działalności serwisu
                  sprawdz-kosztorys.pl.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Regulamin zawiera opis praw oraz obowiązków Użytkownika oraz
                  Usługodawcy.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W regulaminie zawarto rodzaj świadczonych przez Usługodawcę
                  usług oraz ich zakres i zasady.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Rozpoczęcie korzystania z Usług powinno zostać przez
                  Użytkownika poprzedzone zapoznaniem się z Regulaminem i
                  Polityką Prywatności.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Korzystanie z Serwisu oznacza akceptację Regulaminu oraz
                  zobowiązanie się do przestrzegania jego zasad, rezultatem
                  czego jest zawarcie umowy o świadczenie usług drogą
                  elektroniczną.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik zgadza się, aby do celów związanych z realizacją
                  Usługi jego dane osobowe były przetwarzane na zasadach
                  określonych w integralnej części niniejszego Regulaminu, tj.
                  Polityce Prywatności.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W sprawach, które nie są regulowane przez Regulamin
                  zastosowanie mają przepisy Kodeksu Cywilnego bądź inne akty
                  prawne.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § III Zawarcie umowy o świadczenie Usług drogą elektroniczną oraz
              jej rozwiązanie
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Regulamin i Polityka Prywatności to część umowy o świadczenie
                  usług drogą elektroniczną.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Korzystanie z Usług musi zostać poprzedzone akceptacją
                  Regulaminu i Polityki Prywatności, co jest skorelowane z
                  zawarciem umowy o świadczenie usług drogą elektroniczną. Nie
                  ma w tym przypadku potrzeby podpisywania odrębnej umowy.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Umowa o świadczenie usług drogą elektroniczną trwa tyle samo
                  czasu, ile korzystanie z Serwisu przez Użytkownika.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Umowa ulega automatycznemu rozwiązaniu w momencie opuszczenia
                  Serwisu bądź zakończenia rozmowy z Konsultantem Call Center.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § IV Przekazanie danych Użytkownika do Partnerów
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Komunikacja dot. Usług odbywa się za pośrednictwem
                  znajdujących się na Serwisie komunikatów, telefonicznie -
                  jeśli Użytkownik korzysta z Usług za pomocą Call Center bądź
                  e-mailowo - w przypadku podania przez Użytkownika adresu
                  e-mail.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Z Usługodawcą można się skontaktować za pomocą tradycyjnej
                  poczty, dzwoniąc na infolinię, korzystając z formularzy
                  kontaktowych, lub wyrażając chęć kontaktu telefonicznego
                  poprzez zaznaczenie w Serwisie prośbę o kontakt telefoniczny.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca może udzielać odpowiedzi za pośrednictwem poczty
                  tradycyjnej, poczty elektronicznej lub telefonicznie.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3 ">
            <Typography as="h2" variant="header-3">
              § V Ogólne zasady świadczenia Usług drogą elektroniczną
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługi drogą elektroniczną, które są realizowane za pomocą
                  Serwisu są dostępne przez cały tydzień i całodobowo. Z kolei
                  usługi świadczone za pośrednictwem Call Center są dostępne w
                  dniach i godzinach pracy Usługodawcy.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownikowi nie są naliczane żadne opłaty za korzystanie z
                  Serwisu.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zarówno działanie Serwisu, jak i Call Center może zostać
                  zakłócone przez przerwy związane z koniecznością np.
                  przeprowadzenia prac modernizacyjnych.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca oświadcza, że jeśli sprzęt i oprogramowanie
                  Użytkownika nie będzie spełniać minimalnych wymogów, to Serwis
                  może działać w sposób nieprawidłowy.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Realizacja Usług może zostać przerwana z uwagi na słabą jakość
                  połączenia, uszkodzenia sprzętu, systemów zasilania lub przerw
                  w dostawie prądu.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Jeśli Usługodawca stwierdzi, że użytkowanie z Serwisu jest
                  nieprawidłowe, to zastrzega sobie prawo do zablokowania
                  dostępu do niego.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca zastrzega, że nie ponosi odpowiedzialności za
                  czasowe zawieszenie dostępu do Serwisu przez okres konieczny
                  do usunięcia zagrożeń czy nieprawidłowości.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zabronione są jakiekolwiek działania, które mogą wywołać
                  destabilizację Serwisu bądź utrudnienia w jego funkcjonowaniu.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca w przypadku podejrzeń, że dany Użytkownik
                  dopuszcza się wymienionych w w/w pkt. podejmie odpowiednie
                  kroki.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Próba destabilizacji Serwisu w myśl obowiązujących przepisów
                  prawnych jest czynem zabronionym.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik nie może dostarczać treści mających charakter
                  obraźliwy bądź bezprawny.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik nie powinien naruszać prywatności innych osób,
                  działać na szkodę Serwisu, Administratora oraz innych
                  Użytkowników.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Każdy Użytkownik jest zobowiązany do przestrzegania zasad
                  społecznego współżycia oraz obowiązujących norm etycznych i
                  moralnych.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik rozpoczynając korzystanie z dostępnych w Serwisie
                  Usług zgadza się na przetwarzanie danych osobowych.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § VI Prawa własności intelektualnej
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W Serwisie mogą się znajdować dokumenty chronione przez prawo
                  autorskie, znaki towarowe, dobra niematerialne chronione przez
                  prawo własności intelektualnej.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W Serwisie mogą się znajdować dokumenty chronione przez prawo
                  autorskie, znaki towarowe, dobra niematerialne chronione przez
                  prawo własności intelektualnej.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik jest zobowiązany do uzyskania zgody Administratora,
                  jeśli ma zamiar wykorzystać dostępne w Serwisie treści poza
                  użytek osobisty.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § VII Warunki techniczne świadczenia usług
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Aby móc w sposób prawidłowy korzystać za pośrednictwem Serwisu
                  z usług świadczonych drogą elektroniczną Użytkownik powinien
                  posiadać odpowiedni sprzęt oraz oprogramowanie. Minimalne
                  wymogi: włączone pliki Cookies, włączona obsługa JavaScript,
                  przeglądarka internetowa: Chrome wersja 42.0, Internet
                  Explorer wersja 11, Firefox wersja 37.0.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W przypadku korzystania z usług za pośrednictwem Call Center
                  niezbędny jest system teleinformatyczny posiadający możliwość
                  tonowego wybierania numerów.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dane przesyłane w ramach znajdujących się w Serwisie
                  formularzy są chronione przez protokół SSL.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Do teleinformatycznego systemu Użytkownika wprowadzane są tzw.
                  ciasteczka, czyli pliki Cookies.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § VIII Zasady odpowiedzialności
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca zastrzega, że nie bierze odpowiedzialności za
                  podane przez Użytkownika dane, które okażą się nieprawidłowe
                  bądź niepełne.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca oświadcza, że nie odpowiada za niewłaściwe
                  wypełnienie przez Użytkownika formularza elektronicznego oraz
                  podanie przez niego danych osób trzecich bez ich zgody i
                  wiedzy.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik nie może dostarczać do Serwisu takich treści, które
                  są obraźliwe, nieprawdziwe lub wprowadzające w błąd.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Zabrania się także treści zawierających wirusy czy podobne
                  treści powodujące uszkodzenia lub nawet zakłócenia w działaniu
                  systemów komputerowych. Usługodawca zastrzega sobie prawo do
                  zablokowania dostępu do takich danych i nie bierze żadnej
                  odpowiedzialności za powstałe tym sposobem szkody.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca w przypadku uzyskania informacji o danych o
                  charakterze bezprawnym informuje Użytkownika o ograniczeniu
                  dostępu do danych.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Jeśli Usługodawca poniesie szkodę w wyniku dostarczenia przez
                  danego Użytkownika w/w treści i danych, to będzie mógł
                  dochodzić odszkodowania na zasadach ogólnych.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § IX Postępowanie reklamacyjne
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Użytkownik ma prawo złożyć reklamację dot. korzystania z usług
                  Serwisu i Call Center. W tym celu należy wysłać wiadomość za
                  pomocą poczty elektronicznej bądź skontaktować się z
                  Usługodawcą telefonicznie.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W reklamacji powinny się znaleźć takie dane jak: imię i
                  nazwisko Użytkownika, adres e-mail oraz adres
                  korespondencyjny.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  W reklamacji należy przedstawić powód jej złożenia.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca rozpatruje złożoną reklamację w ciągu 30 dni, a
                  jeśli jest to niemożliwe to zawiadamia o powodach opóźnienia
                  odpowiedzi.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Odpowiedź dot. reklamacji jest wysyłana na podany przez
                  Użytkownika adres e-mail lub adres korespondencyjny.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § X Postanowienia końcowe
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Komunikacja pomiędzy Usługodawcą a Użytkownikiem odbywa się w
                  języku polskim.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Dla niniejszego Regulaminu obowiązującym prawem jest polskie
                  prawo.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Ewentualne spory będzie rozpatrywał sąd właściwości ogólnej.
                </Typography>
              </li>
            </ol>
          </div>
          <div className="space-y-3">
            <Typography as="h2" variant="header-3">
              § XI Wejście w życie Regulaminu
            </Typography>
            <ol className="ml-2 list-outside list-decimal space-y-3">
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Niniejszy Regulamin wchodzi w życie z dniem 27.08.2020 r.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Usługodawca zastrzega, że może dokonywać zmian w treści
                  Regulaminu.
                </Typography>
              </li>
              <li className="ml-3">
                <Typography as="p" variant="body-text-2" className="ml-2">
                  Ewentualne zmiany w Regulaminie wchodzą w życie w terminie 14
                  dni od momentu ich ogłoszenia.
                </Typography>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Steps />
    </Layout>
  );
};

export const Head = () => {
  return <Seo title={RulesPage.title} description={RulesPage.description} />;
};
export default Page;
